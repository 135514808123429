/**
 * Type: テンプレート
 * What: カテゴリー一覧
 */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostList from '../components/PostList';
import Pagination from '../components/Pagination';
import SEO from '../components/Seo';

const pageDescription = 'E・ZO FUKUOKAのイベントやお得な情報、キャンペーンなど最新ニュースをお届けしています。';

const Category = (props) => {
  const { data, pageContext } = props;
  const { edges: posts, totalCount } = data.allWordpressPost;
  const { title: siteTitle } = data.site.siteMetadata;
  const { name: category } = pageContext;
  const POST_URL = data.site.siteMetadata.routingSlug.posts;
  const CATEGORIES_URL = data.site.siteMetadata.routingSlug.categories;

  return (
    <Layout>
      <Helmet title={`${category} | ${siteTitle}`} />
      <SEO
        title={category}
        description={pageDescription}
      />
      <PostList
        posts={posts}
        title="新着情報一覧"
        baseUrl={POST_URL}
        categoriesUrl={CATEGORIES_URL}
        category={category}
        totalCount={totalCount}
      />
      <Pagination pageContext={pageContext} pathPrefix="/" />
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        routingSlug {
          posts
          categories
        }
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
